import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Button, Col, Select, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { reportsActions } from "services/Reports/ReportsSlice";
/* import ReactExport from "react-export-excel"; */


export const ModalDownloadBankFormat = (selectedRows) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { dispersions, pagination, filterData, reload } = useSelector(state => state.dispersion)
  const { reportBankDis, status } = useSelector(state => state.reports)

  /*   const ExcelFile = ReactExport.ExcelFile; 
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn; */
  const [selectedBank, setSelectedBank] = useState(null);

  const handleBankChange = (value) => {
    setSelectedBank(value);
  };

  useEffect(() => {
    if (reportBankDis != null) {
      //const link = document.createElement('a');
      //link.href = URL.createObjectURL(reportBankDis); // Creamos un objeto URL a partir del Blob
      //link.download = 'dispersion.xlsx'; // Nombre del archivo para la descarga
      //link.click(); // Disparamos el clic para iniciar la descarga

      dispatch(reportsActions.onReportResponse(null));
    }
  }, [reportBankDis]);

  const downloadFile = async () => {
    let data = [];
    let data_sel = selectedRows.selectedRows;
    // Verifica si selectedRows es un array
    if (!Array.isArray(data_sel)) {
      return;
    }

    // Extrae los ids seleccionados
    const selectedIds = data_sel.map(row => row.id);

    const filteredDispersions = dispersions.filter(dispersion =>
      selectedIds.includes(dispersion.id)
    );
    if (selectedBank == "bancolombia") {
      data = await (dataProccesorExcelBc(filteredDispersions));
    } else {
      data = await (dataProccesorExcel(filteredDispersions));
    }
    //dispatch(reportsActions.generateFileDis({data:data ,type:selectedBank})); 
    dispatch(reportsActions.generateFileDis({ data, type: selectedBank }));

  }
  const dataProccesorExcel = (col) => {
    return col.map(row => {

      let bankCode = 0;
      let code_piv = 0;
      let userBankData = row.metadata[0]?.data_user_bank;
      let userData = row.metadata[0]?.data_user;
      if (row.userId == "1") {
        if (userBankData != null) {
          code_piv = userBankData.bankAccountDistribution?.bank.id;
        }

      } else {
        let adminData = row.metadata[0]?.data_user_bank;
        if (adminData != null) {
          code_piv = adminData.bank.id;
        }
      }

      switch (code_piv) {
        case 1://Bank of America
          bankCode = '';
          break;
        case 2://Banco Agrario
          bankCode = 40;
          break;
        case 3://Banco AV Villas
          bankCode = 52;
          break;
        case 4://Banco Caja Social
          bankCode = 32;
          break;
        case 5://Banco de Occidente
          bankCode = 23;
          break;
        case 6://Banco Popular
          bankCode = 2;
          break;
        case 7://Bancolombia
          bankCode = 7;
          break;
        case 8://BBVA Colombia
          bankCode = 13;
          break;
        case 9://Banco de Bogotá
          bankCode = 1;
          break;
        case 10://Citi Colombia
          bankCode = 9;
          break;
        case 11://Colpatria
          bankCode = 19;
          break;
        case 12://Davivienda
          bankCode = 51;
          break;
        case 13://GNB Sudameris
          bankCode = 12;
          break;
        case 20://efectivo
          bankCode = 20;
          break;
        case 21://otro
          bankCode = 21;
          break;
        case 19://bancoomeva
          bankCode = 1061;
          break;
      }

      let bankAccType = 0;
      let bankAccTypePiv = userBankData?.bankAccountTypeId;
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          bankAccTypePiv = adminData.bankAccountDistribution.typeProdServ;
        }
      }
      switch (bankAccTypePiv) {
        case 1:
        case 3:
          bankAccType = 'CA';
          break;
        case 2:
        case 4:
          bankAccType = 'CC';
          break;
      }

      let docidCode = 0;
      switch (userData?.docid[0]?.docidTypeId) {
        case 1:
          docidCode = 5;
          break;
        case 2:
          docidCode = 'Licencia de conduccion';
          break;
        case 3:
          docidCode = 1;
          break;
        case 4:
          docidCode = 2;
          break;
        case 5:
          docidCode = 3;
          break;
      }

      let code = (userData?.docid[0]?.code);
      let name = (userData?.profiles[0]?.name);
      let lastname = (userData?.profiles[0]?.lastname);
      let number = (userBankData?.number);
      let valuePay = (row.valuePay);
      let email = (userData?.email);
      let propertyName = row.metadata[0]?.prop_name;

      ///si es de tipo administracion
      //if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          code = (adminData.bankAccountDistribution.numId);
          name = (adminData.bankAccountDistribution.name);
          lastname = (adminData.bankAccountDistribution.lastname);
          number = (adminData.bankAccountDistribution.numProdServ);
          valuePay = (row.valuePay);
          email = (adminData.bankAccountDistribution.email);
          propertyName = row.metadata[0]?.prop_name;

        }
      //}

      if ((docidCode == 0 || bankAccType == 0 || bankCode == 0) &&
        (code == undefined || name == undefined ||
        lastname == undefined || number == undefined ||
        valuePay == undefined || email == undefined)
      ) {
        return null
      } else {
        return {
          typeDoc: docidCode,
          docNum: code,
          name: name,
          lastName: lastname,
          codeBank: bankCode,
          typeProduct: bankAccType,
          numProd: number,
          valueTotal: valuePay,
          reference: "ARRIENDO",
          email: email,
          description: propertyName
        };
      }
    }).filter(row => row !== null);;

  }

  const dataProccesorExcelBc = (col) => {
    const fecha = new Date();

    const año = fecha.getFullYear(); // Año completo (ej. 2024)
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Mes (1 a 12), se le suma 1 y se formatea a dos dígitos
    const día = String(fecha.getDate()).padStart(2, '0'); // Día (1 a 31), formateado a dos dígitos
    const date_ret = `${año}${mes}${día}`;

    const processedData = col.map(row => {
      let bankCode = 0;
      let code_piv = 0;
      let userBankData = row.metadata[0]?.data_user_bank;
      let userData = row.metadata[0]?.data_user;
      if (row.userId == "1") {
        if (userBankData != null) {
          code_piv = userBankData.bankAccountDistribution?.bank.id;
        }

      } else {
        let adminData = row.metadata[0]?.data_user_bank;
        if (adminData != null) {
          code_piv = adminData.bank.id;
        }
      }

      switch (code_piv) {
        case 2://Banco Agrario
          bankCode = 1040;
          break;
        case 3://Banco AV Villas
          bankCode = 1052;
          break;
        case 4://Banco Caja Social
          bankCode = 1032;
          break;
        case 5://Banco de Occidente
          bankCode = 1023;
          break;
        case 6://Banco Popular
          bankCode = 1002;
          break;
        case 7://Bancolombia
          bankCode = 1007;
          break;
        case 8://BBVA Colombia
          bankCode = 1013;
          break;
        case 9://Banco de Bogotá
          bankCode = 1001;
          break;
        case 10://Citi Colombia
          bankCode = 1009;
          break;
        case 11://Colpatria
          bankCode = 1019;
          break;
        case 12://Davivienda
          bankCode = 1051;
          break;
        case 13://GNB Sudameris
          bankCode = 1012;
          break;
        case 20://efectivo
          bankCode = 1020;
          break;
        case 21://otro
          bankCode = 0;
          break;
        case 19://bancoomeva
          bankCode = 1061;
          break;
      }

      let bankAccType = 0;
      let typeTrans = 0;
      let bankAccTypePiv = userBankData?.bankAccountTypeId;
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          bankAccTypePiv = adminData.bankAccountDistribution.typeProdServ;
        }
      }
      switch (bankAccTypePiv) {
        case 1:
        case 3:
          bankAccType = 'CA';
          typeTrans = 37;
          break;
        case 2:
        case 4:
          bankAccType = 'CC';
          typeTrans = 27;
          break;
      }

      let docidCode = 0;
      switch (userData?.docid[0]?.docidTypeId) {
        case 1:
          docidCode = 5;
          break;
        case 2:
          docidCode = 'Licencia de conduccion';
          break;
        case 3:
          docidCode = 1;
          break;
        case 4:
          docidCode = 2;
          break;
        case 5:
          docidCode = 3;
          break;
      }

      let code = (userData?.docid[0]?.code);
      let name = (userData?.profiles[0]?.name);
      let lastname = (userData?.profiles[0]?.lastname);
      let number = (userBankData?.number);
      let valuePay = (row.valuePay);
      let email = (userData?.email);
      let propertyName = row.metadata[0]?.prop_name;

      ///si es de tipo administracion
      //if (row.userId == "1") {
      let adminData = row.metadata[0]?.admin_data;
      if (adminData != null) {
        code = (adminData.bankAccountDistribution.numId);
        name = (adminData.bankAccountDistribution.name);
        lastname = (adminData.bankAccountDistribution.lastname);
        number = (adminData.bankAccountDistribution.numProdServ);
        valuePay = (row.valuePay);
        email = (adminData.bankAccountDistribution.email);
        propertyName = row.metadata[0]?.prop_name;

      }
      //} 
      if ((docidCode == 0 || bankAccType == 0 || bankCode == 0) && (
        code == undefined || name == undefined ||
        lastname == undefined || number == undefined ||
        valuePay == undefined || email == undefined)) { 
        return null
      } else {
        return {
          typeDoc: docidCode,
          docNum: code,
          name: name + ' ' + lastname,
          typeTrans: typeTrans,
          codeBank: bankCode,
          numProd: number,
          email: email,
          docAuthorized: '',
          reference: "ARRIENDO " + ' ' + propertyName,
          phone: '',
          value: valuePay,
          dateApplay: date_ret
        };
      }
    }).filter(row => row !== null);;

    return [...processedData];

  }

  // Función para generar ExcelSheet para Bancolombia
  const renderBancolombiaExcelSheet = (data) => (
    <ExcelSheet data={data} name="Estado de cuenta">
      <ExcelColumn value={(col) => col.typeDoc || ''} />
      <ExcelColumn value={(col) => col.docNum || ''} />
      <ExcelColumn value={(col) => col.name + ' ' + col.lastName || ''} />
      <ExcelColumn value={(col) => col.typeTrans || ''} />
      <ExcelColumn value={(col) => col.codeBank || ''} />
      <ExcelColumn value={(col) => col.numProd || ''} />
      <ExcelColumn value={(col) => col.email || ''} />
      <ExcelColumn value={(col) => col.docAuthorized || ''} />
      <ExcelColumn value={(col) => col.reference || ''} />
      <ExcelColumn value={(col) => col.phone || ''} />
      <ExcelColumn value={(col) => col.value || ''} />
      <ExcelColumn value={(col) => col.dateApplay || ''} />
    </ExcelSheet>
  );

  // Función para generar ExcelSheet para Davivienda
  const renderDaviviendaExcelSheet = (data) => (
    <ExcelSheet data={data} name="Estado de cuenta">
      <ExcelColumn label="Tipo de identificación" value={(col) => col.typeDoc} />
      <ExcelColumn label="Número de identificación" value={(col) => col.docNum} />
      <ExcelColumn label="Nombre" value={(col) => col.name} />
      <ExcelColumn label="Apellido" value={(col) => col.lastName} />
      <ExcelColumn label="Código del banco" value={(col) => col.codeBank} />
      <ExcelColumn label="Tipo de Producto" value={(col) => col.typeProduct} />
      <ExcelColumn label="Número del Producto" value={(col) => col.numProd} />
      <ExcelColumn label="Valor total pago" value={(col) => col.valueTotal} />
      <ExcelColumn label="Referencia" value={(col) => col.reference} />
      <ExcelColumn label="Correo Electrónico" value={(col) => col.email} />
      <ExcelColumn label="Descripción" value={(col) => col.description} />
    </ExcelSheet>
  );

  return (
    <Row>
      <Spin spinning={status === "loading"}>
        {dispersions.length ? (
          <>
            <Row justify="end" style={{ marginTop: '0px' }}>
              <Col>
                <label>El archivo se generara con los registros que esten listos para su procesamiento en las plataformas bancarias.
                </label>
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: '0px' }}>

              <Col>
                <>
                  Seleccione un formato :
                </>
                <Select
                  style={{ width: 200, margin: '5px' }}
                  placeholder="Seleccione un banco"
                  onChange={handleBankChange}
                >
                  <Option value="bancolombia">Bancolombia</Option>
                  <Option value="davivienda">Davivienda</Option>
                </Select>
              </Col>

              <Col>
                {selectedBank ? <Button
                  style={{
                    borderRadius: '5px',
                    borderColor: '#d82067',
                    padding: '5px 31px',
                    height: 'auto',
                    fontSize: 'medium',
                    marginTop: "5px"
                  }}
                  type="primary"
                  onClick={() => downloadFile()}
                >
                  Generar
                </Button> : ""}
              </Col>
            </Row>
          </>
        ) : null}
      </Spin>
    </Row>
  );
};