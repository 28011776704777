import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { authActions } from 'services/Auth/AuthSlice';
/* mport { InputForm } from 'components/form/input/InputForm'; */
import { Modal, Button, Form, Input } from 'antd';

export const RecoverPassword = ({ closeModal }) => {
  const dispatch = useDispatch()
  const { company, loading } = useSelector(state => state.auth)
  const [message, setMessage] = useState("");


  const onSubmit = (values) => {
    console.log('Form values:', values);
    dispatch(authActions.recoveryPassword(values)) 
    //closeModal(false);
    setMessage("Hemos enviado un correo al email ingresado, verifica tu bandeja de entrada o spam");
  };
  const handleClose = () => {
    setMessage(""); // Limpia el mensaje al cerrar el modal
    closeModal(false);
  };

  return (
    <div className="recover-password">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        onValuesChange={(changedValues, allValues) => {
          console.log('Changed values:', changedValues);
          console.log('All form values:', allValues);
        }}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="¿Cuál es tu correo electrónico?"
          tooltip="This is a required field"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un correo válido!',
              type: 'email'
            }
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Correo" />
        </Form.Item>
        <Form.Item>
          <button type="submit">Enviar</button>
        </Form.Item>
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        )}
      </Form>
      {message && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            color: "#1890ff",
          }}
        >
          {message}
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <button
          style={{
            border: '1px solid #d82067', // Define el borde para que se vea el color
            borderRadius: '5px',
            backgroundColor: 'transparent', // Fondo transparente si no deseas un color de fondo
            color: '#d82067', // Color del texto
            padding: '5px 31px',
            fontSize: 'medium',
            cursor: 'pointer', // Cursor para indicar que es interactivo
          }}
          onClick={handleClose}>Cerrar</button>
      </div>
    </div>
  )
}