import React, { useState, useEffect, Fragment } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { feesActions } from "services/Fees/FeesSlice";
import { contractActions } from "services/Contract/ContractSlice";
export const FeesConfigModal = ({ visible, dataAct, contract }) => {

  const dispatch = useDispatch()
  const history = useHistory();
  const { plans } = useSelector((state) => state.billing);
  const [form] = Form.useForm();
  const [loaded, setLoaded] = useState(0);
  const [isPercent, setIsPercent] = useState('Seleccione un valor');
  const [isPercentInmo, setIsPercentInmo] = useState('Seleccione un valor');
  const [isPercentSecure, setIsPercentSecure] = useState('Seleccione un valor'); 
  const [isPlanSel, setIsPlanSel] = useState(0);
  const { contextData } = useSelector(state => state.fees);
  const [isChecked, setIsChecked] = useState(true);
  const [listPlans, setListPlans] = useState([]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Invertir el valor actual del estado
  };

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ contextData:", dataAct)
    if (contextData !== undefined) {
      console.log("data actual ", contextData.id);
      // se asigna la nueva configuracion al contrato
      const pay = {
        payload: {
          contract_id: contract.id,
          contract_type_id: contract.contract_type_id,
          dispersion_context_id: contextData.id,
        },
      }
      dispatch(contractActions.editContract(pay));
    } else {
    }
  }, [contextData])

  useEffect(() => {
    if (dataAct) {
       if(loaded == 0){
        setIsPercent(dataAct.type)
        setIsPercentInmo(dataAct.type_inmo)
        setIsPercentSecure(dataAct.type_secure)
        setIsPlanSel(dataAct.accountant_billing_plan)
        setLoaded(1);
       } 
    }else{
      return () => {
        dispatch(contractActions.getContract(contract.id))
      }
    }
   
  }, [dataAct])

  useEffect(() => { 
      if (plans.length > 0) {  
        const data_select = plans.map(item => ({
          value: item.id,
          label: item.name, 
        })); 
        setListPlans(data_select);
       } 
    
  }, [plans])
  const handleChange = (value, option) => { 
    setIsPlanSel(value); 
  };

  const onFinish = (values) => {
    let payload =
    {
      payload: {
        "entity_id": 6,
        "taxes": 0.0,
        "country_id": 2,
        "commission": parseFloat(values.commission),
        "commission_inmo": parseFloat(values.commission_inmo),
        "commission_secure": parseFloat(values.commission_secure),
        "name": "contract_conf_com_cus",
        "description": "Configuracion de tarifas del contrato",
        "metadata": { "base_inmo": true, "inmo_id": 0 },//depende si es nuevo o no
        "type": isPercent,
        "type_inmo": isPercentInmo,
        "type_secure": isPercentSecure,
        "accountant_billing_plan" : isPlanSel
      },
      data_id: dataAct.id
    }

    if (isPercent != 'Seleccione un valor' &&
      isPercentInmo != 'Seleccione un valor' &&
      isPercentSecure != 'Seleccione un valor') {
      //if (isChecked) { 
      //  dispatch(feesActions.createDispersionContext(payload));
      //} else {
        dispatch(feesActions.updateDispersionContext(payload));
      //}
    }
  };

  

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {/*      <label>crear un nuevo registro de configuracion</label>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      /> */}
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 }}>
          <span>Fee de Yampi</span>
        </Col>
        <Col>
          <Form.Item name="commission" initialValue={dataAct.commission}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={isPercent}
            onChange={(value) => setIsPercent(value)}
            options={[
              {
                value: 0,
                label: 'Porcentual',
              },
              {
                value: 1,
                label: 'Nominal',
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 }}>Fee inmobiliaria</Col>
        <Col>
          <Form.Item name="commission_inmo" initialValue={dataAct.commission_inmo}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={isPercentInmo}
            onChange={(value) => setIsPercentInmo(value)}
            options={[
              {
                value: 0,
                label: 'Porcentual',
              },
              {
                value: 1,
                label: 'Nominal',
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 }}>Fee Aseguradora</Col>
        <Col>
          <Form.Item label="" name="commission_secure" initialValue={dataAct.commission_secure}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col>
          <Select
            value={isPercentSecure}
            onChange={(value) => setIsPercentSecure(value)}
            options={[
              {
                value: 0,
                label: 'Porcentual',
              },
              {
                value: 1,
                label: 'Nominal',
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col lg={24} style={{ marginBottom: 16 }}>Plan de facturacion</Col> 
        <Col>
        <Select
            showSearch
            value={isPlanSel}
            placeholder={"Planes disponibles"}
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={handleChange}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={listPlans}
          >
          </Select>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
        <label>  </label>
      </Form.Item>
    </Form>
  );
};

