import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { billingActions } from "./BillingSlice";

function* getList({ payload }) {
  yield put(billingActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter } = payload;
    const response = yield ApiService.requestPost(`/billing/bill/${filter.company_id}?page=${page}`, {
      ...filter,
      limit,
    });
    let { items, meta } = response.payload;
    if (response.response) {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items: [], pagination, filter }));
    } else {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items, pagination, filter }));
    }
    yield put(billingActions.onChangeStatus("success"));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}

function* getPlans({ payload }) {
  yield put(billingActions.onChangeStatus("loading"));
  try {
    const response = yield ApiService.request(`/billing/plans`,
      "GET");

    let items = response.payload;
    yield put(billingActions.setPlans({ items }));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}

function* getListDescription({ payload }) {

  yield put(billingActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter } = payload;
    const response = yield ApiService.request(`/billing/bill-description/${filter.bill_id}?page=${page}`, "GET", {
      ...filter,
      limit,
    });
    console.log(response);
    let { items, meta } = response.payload;
    if (response.response) {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setListsDescription({ items: [], pagination, filter }));
    } else {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setListsDescription({ items, pagination, filter }));
    }
    yield put(billingActions.onChangeStatus("success"));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}


function* getListBillProp({ payload }) {
  yield put(billingActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter } = payload;
    const response = yield ApiService.requestPost(`/billing-prop/list-props-bill?page=${page}`, {
      ...filter,
      limit,
    });
    let { items, meta } = response.payload;
    if (response.response) {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setListsProps({ items: [], pagination, filter }));
    } else {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setListsProps({ items, pagination, filter }));
    }
    yield put(billingActions.onChangeStatus("success"));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}

function* regenBillInmo({ payload }) {
  yield put(billingActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter, bill_id } = payload;
    const response_regen = yield ApiService.request(`/billing/bill/regen-bill/${bill_id}`,
      "GET");
    const response = yield ApiService.requestPost(`/billing/bill/${filter.company_id}?page=${page}`, {
      ...filter,
      limit,
    });
    let { items, meta } = response.payload;
    if (response.response) {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items: [], pagination, filter }));
    } else {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items, pagination, filter }));
    }
    yield put(billingActions.onChangeStatus("success"));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}
 
function* sendBillAccountantSystem({ payload }) {
  yield put(billingActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter, bill_id } = payload;
    const response_regen = yield ApiService.request(`/billing/bill-generate-accountant/${bill_id}`,
      "GET");
    const response = yield ApiService.requestPost(`/billing/bill/${filter.company_id}?page=${page}`, {
      ...filter,
      limit,
    });
    let { items, meta } = response.payload;
    if (response.response) {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items: [], pagination, filter }));
    } else {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items, pagination, filter }));
    }
    yield put(billingActions.onChangeStatus("success"));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}

function* sendAllToBillAccountantSystem({ payload }) {
  yield put(billingActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter, bills_ids } = payload;
    const response_bills = yield ApiService.requestPost(`/billing/bill-generate-accountant-list`, {
     bills_ids
    });
    const response = yield ApiService.requestPost(`/billing/bill/${filter.company_id}?page=${page}`, {
      ...filter,
      limit,
    });
    let { items, meta } = response.payload;
    if (response.response) {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items: [], pagination, filter }));
    } else {
      const pagination = { page: meta.currentPage, total: meta.totalItems, limit, meta };
      yield put(billingActions.setLists({ items, pagination, filter }));
    }
    yield put(billingActions.onChangeStatus("success"));
  } catch (err) {
    yield put(billingActions.onError(err.toString()));
  }
}



function* ActionWatcher() {
  yield takeLatest(billingActions.getList, getList);
  yield takeLatest(billingActions.getPlans, getPlans);
  yield takeLatest(billingActions.getListDescription, getListDescription);
  yield takeLatest(billingActions.getListBillProp, getListBillProp);
  yield takeLatest(billingActions.regenBillInmo, regenBillInmo);
  yield takeLatest(billingActions.sendBillAccountantSystem, sendBillAccountantSystem); 
  yield takeLatest(billingActions.sendAllToBillAccountantSystem, sendAllToBillAccountantSystem);
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
