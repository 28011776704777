import React, { useEffect, useState } from "react";
import { Select, Button, DatePicker, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { companyActions } from "services/Company/CompanySlice";
import moment from "moment/moment";
import 'moment/locale/es'
import locale from 'antd/es/locale/es_ES';

export const Filter = () => {

    const dispatch = useDispatch()
    const [dataInmo, setDataInmo] = useState([]);
    const [dataInmoSel, setDataInmoSel] = useState(null);
    const formRef = React.createRef();
    const { companyAd, companys, pagination } = useSelector((state) => state.company);
    const { company } = useSelector(state => state.user);
    const { user: authUser } = useSelector((state) => state.auth);

    const { page, limit, total } = pagination;
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(formattedDate);
   

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = authUser?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        console.log("🚀 ~ data ~ companys:", isAdmin)
        if (isAdmin) {
            const data = companys?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setDataInmo(data)
        } else {
            if(company != undefined){ 
                const data = company?.map((item) => ({
                    value: item?.company_data?.id,
                    label: item?.company_data?.name,
                })); 
                setDataInmoSel(data[0].value)
                setDataInmo(data)
            } 
        }

    }, [companys])

    const handleFilter = (e) => {

        console.log(e.month);
        let filter_data = {};
        if(dataInmoSel != null ){
           filter_data.company_id = dataInmoSel;
        }else{
            filter_data.company_id = e.company_id;
        }
        
        if (e.month) {
            let date = startDate.toISOString().split('T')[0];
            console.log(date);
            filter_data.date_end = date;
        }
        dispatch(companyActions.getRenewContractList({ page, limit, filter: filter_data }))
    };

    const onReset = () => {
        formRef.current.resetFields();
    };

    return (
        <Form
            name="filterConciliation"
            initialValues={{
                remember: true,
            }}
            layout="inline"
            onFinish={handleFilter}
            autoComplete="off"
            ref={formRef}
        ><Form.Item
            name="company_id"
            rules={[
                {
                    required: false,
                },
            ]}
        >
                <Select
                    showSearch
                    placeholder={"Inmobiliaria"}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={dataInmo}
                />
            </Form.Item>
            <Form.Item
                name="month"
                rules={[
                    {
                        required: false,
                        message: 'Seleccione un mes',
                    },
                ]}
            >

                <DatePicker onChange={setStartDate} picker="month" placeholder="mes y año"  />

            </Form.Item>
            <Form.Item >
                <Button
                    style={{
                        borderRadius: '5px',
                        borderColor: '#d82067',
                        padding: '5px 31px',
                        height: 'auto',
                        fontSize: 'medium',
                    }}
                    type="primary"
                    htmlType="submit"
                >
                    Buscar
                </Button>
            </Form.Item>
            <Form.Item>
                <Button className="btn-default" htmlType="button" onClick={onReset}>
                    Limpiar
                </Button>
            </Form.Item>
        </Form >
    );
};
