
import { useEffect, useState } from 'react';
import { Card, Modal, Descriptions, Form, Input, Button, InputNumber, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux'; 
import { companyActions } from 'services/Company/CompanySlice';

export const ConfigurationBill = () => {
     const dispatch = useDispatch()
    const { companyConfAd } = useSelector((state) => state.company);
    const [form] = Form.useForm();

    useEffect(() => {
        if (companyConfAd && typeof companyConfAd === "object" && Object.keys(companyConfAd).length > 0) {
            form.setFieldsValue({
                ...companyConfAd
            });
        }else{
            form.setFieldsValue({
                billValueStatic : 0
            });
        }

    }, [companyConfAd,form])


    const onFinishCon = (values) => { 
        if(companyConfAd?.companyId){
            values.configId = companyConfAd.id;
            values.companyId = companyConfAd.companyId;
            values.billValueStaticActive = true;
            console.log(values);
            dispatch(companyActions.setUpdateCompanyConfigBill(values));
        } 
    };


    return (
        <Card title="" bordered={false}>
            <Form
                form={form}
                onFinish={onFinishCon}
            >
                <label>Valor de tarifa fija</label>
                <Form.Item
                    name="billValueStatic"
                    initialValue={0} // Valor inicial
                    rules={[{ required: true, message: "Escriba un valor" }]}
                >
                    <InputNumber
                        placeholder="Ingrese valor"
                        style={{ width: 150 }}
                        min={0}
                        step={0.01}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                </Form.Item> 
                <Form.Item>
                    <Button type="primary" htmlType="submit" >
                        Actualizar
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}