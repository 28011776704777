import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { Button, Col, Modal, PageHeader, Row, Spin, Table } from 'antd'
import { columns, tablePaginationBill } from './constans';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBillDetail } from './sections/ModalBillDetail';
import { billingActions } from 'services/Billing/BillingSlice';
export const Billing = () => {

  const dispatch = useDispatch()
  const currentTablePagination = tablePaginationBill();
  const { pagination, list, status, filter } = useSelector(state => state.billing);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bill, setBill] = useState(false);
  const { page, limit } = pagination
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const currentColumn = columns(isModalVisible, setIsModalVisible, bill, setBill, selectedRows, setSelectedRows);
  const handleOk = async () => {
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
    dispatch(billingActions.setListsDescription({ items: [], pagination, filter }))
  };


  // Función para seleccionar todas las filas
  const handleSelectAll = () => {
    if (list.length >= 1) {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        let valid_reg = [];
        list.forEach(element => {
          if (element.codeBilling == null) {
            valid_reg.push(element)
          }
        });
        setSelectedRows(valid_reg); // Asigna todas las filas como seleccionadas
      }
      setSelectAll(!selectAll); // Cambia el estado de selección de todos los checkboxes
    }
  };

  const sendAllToAccountantSistem = () => {
    if (selectedRows.length >= 1) {
      let valid_reg = [];
      selectedRows.forEach(element => {
        valid_reg.push(element.id)
      });
      let answer = window.confirm("Desea enviar la informacion seleccionada par afacturacion en sistema contable??");
      if (answer) {
        let filter_send = { ...filter };
        if (filter.company_id === undefined) {
          filter_send.company_id = 0;
        }
        console.log(valid_reg);
        dispatch(billingActions.sendAllToBillAccountantSystem({ page, limit, filter: filter_send, bills_ids: valid_reg }))
      } 
    }
  };


  return (
    <div className="sysblueprint">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >
        <Filter />

        <Row gutter={16} style={{ marginTop: '10px' }}>
          <Col>
            <Button
              type="primary"
              onClick={handleSelectAll}
            >
              {selectAll ? 'Desseleccionar todo' : 'Seleccionar todo'}
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              onClick={() => sendAllToAccountantSistem()}
            >
              Aprobar
            </Button>
          </Col>
        </Row>

      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
            </>
          }
          columns={currentColumn}
          dataSource={list}
          pagination={currentTablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={""}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalBillDetail
            bill={bill}
          />
        </Modal>
      }
    </div>
  )
}