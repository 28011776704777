import { put, all, takeLatest } from "redux-saga/effects";
import { contractActions } from "./ContractSlice";
import { propertyActions } from "../Property/PropertySlice";
import ApiService from "../../common/api/Api";
import { useNotificationDispatchContext } from "common/utilities/notification";
import { useDispatch } from "react-redux";
import { appActions } from "services/App/AppSlice";

function* getContract({ payload }) {
  try {
    yield put(contractActions.onChangeStatus("loading"));
    const response = yield ApiService.post(`/admin/contract/${payload}`);

    yield put(contractActions.setContract(response));
    yield put(contractActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
      contractActions.onError("No se pudo obtener el listado de contratos")
    );
  }
}

function* getConfigIpc({ payload }) {
  try {
    yield put(contractActions.onChangeStatus("loading"));
    const response = yield ApiService.get(`/admin/show-conf-contract/${payload}`);

    yield put(contractActions.setContractConfigIpc(response));
    yield put(contractActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
      contractActions.onError("No se pudo obtener la configuracion")
    );
  }
}

function* getPayents({ payload }) {
  try {
    yield put(contractActions.onChangeStatus("loading"));
    const response = yield ApiService.requestPost(`/shoppingCart/get-payents`, payload);

    yield put(contractActions.setPayents(response))
    yield put(contractActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
      contractActions.onError("No se pudo obtener el listado de pagos")
    );
  }
}

function* editSpecialContract({ payload }) {
  try {
    yield put(contractActions.onChangeStatus("loading"));
    const response = yield ApiService.requestPut(`/shoppingCart/update-payent`, payload);

    yield put(contractActions.getPayents({ contract_id: 68 }));
    yield put(contractActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
      contractActions.onError("No se pudo obtener el listado de contratos")
    );
  }
}

function* renterDefineNewContract({ payload }) {
  try {
    const response = yield ApiService.post(
      "/admin/renter-add-new-contract",
      payload.payload
    );
    if (response.id) {
      yield put(contractActions.onChangeStatus("success"));
      yield put(contractActions.setContract(response));
      yield put(propertyActions.getProperty(payload.id));
      return response;
    }
    /*yield put(contractActions.setContract(response))
     */
  } catch (err) {
    yield put(contractActions.onError("No se pudo realizar la accion"));
    yield put(contractActions.onAlert(true));
  }
}

function* newContractSign({ payload }) {
  try {
    const response = yield ApiService.post(
      "/auco/init-sign",
      payload.payload
    );
    if (response.id) {
      yield put(contractActions.onChangeStatus("success"));
      /* yield put(propertyActions.getProperty(payload.id)); */
      return response;
    }
    /*yield put(contractActions.setContract(response))
     */
  } catch (err) {
    yield put(contractActions.onError("No se pudo realizar la accion"));
    yield put(contractActions.onAlert(true));
  }
}

function* editContract({ payload, id }) {
  try {
    const response = yield ApiService.post(
      "/admin/contract-update",
      payload.payload
    );
    if (payload.cost) {
      const res = yield ApiService.post(
        `/cost/${payload.idContract}/contract`,
        payload.cost
      );
    }
    yield put(contractActions.getContract(payload.payload.contract_id));
    if (response.id) {
      yield put(propertyActions.getProperty(payload.idProperty));
      yield put(contractActions.onChangeStatus("ok"));
      return response;
    }
    /*yield put(contractActions.setContract(response))
     */
  } catch (err) {
    yield put(contractActions.onError("No se pudo realizar la accion"));
    yield put(contractActions.onAlert(true));
  }
}

function* editContractV2({ payload, id }) {
  try {
    const response = yield ApiService.requestPost(`/contract/update`,  payload.payload);  
    if (payload.cost) {
      // TODO cambiar a actualizr costo a node
      const res = yield ApiService.post(
        `/cost/${payload.idContract}/contract`,
        payload.cost
      );
    }
    yield put(contractActions.getContract(payload.payload.contract_id));
    if (response.id) {
      yield put(propertyActions.getProperty(payload.idProperty));
      yield put(contractActions.onChangeStatus("ok"));
      return response;
    }
    /*yield put(contractActions.setContract(response))
     */
  } catch (err) {
    yield put(contractActions.onError("No se pudo realizar la accion"));
    yield put(contractActions.onAlert(true));
  }
}


function* editDayPay({ payload }) {
  try {
    const response = yield ApiService.requestPost(
      "/contract_adjust_payment/gen-payment",
      payload
    );

    if (response.success == "OK") {
      yield put(contractActions.onChangeStatus("ok"));
      yield put(appActions.openSnackbar({
        type: 'success',
        message: 'OK',
        description: 'Se modifico con exito los días de recaudo'
      }))
      yield put(contractActions.getContract(payload.contractId));
      return response;
    }
    /*yield put(contractActions.setContract(response))
     */
  } catch (err) {
    console.log("🚀 ~ function*editDayPay ~ err:", err)
    yield put(appActions.openSnackbar({
      type: 'error',
      message: 'Ups!',
      description: 'Ocurrio un error intenta mas tarde'
    }))
    yield put(contractActions.onError("No se pudo realizar la accion"));
    yield put(contractActions.onAlert(true));
  }
}

function* getPaymentsHide({ payload }) {
  try {
    const response = yield ApiService.get(`/admin/payments-system/${payload.id}`);
    yield put(contractActions.setPaymentsContract(response));
    yield put(contractActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
      contractActions.onError("No se pudo obtener el listado de pagos")
    );
  }
}

function* skipayment({ payload }) {
  try {
    const response = yield ApiService.post(`/admin/skip_payment`, payload);
  } catch (err) {
    yield put(
      contractActions.onError("No se pudo obtener el listado de pagos")
    );
  }
}

function* downContract({ payload }) {
  try {
    const response = yield ApiService.delete(`/admin/contract-close/${payload.id}`);
    yield put(contractActions.setConDelete(true)); 
  } catch (err) {
    yield put(
      contractActions.onError("No se completar la operacion")
    );
  }
}


function* ActionWatcher() {
  yield takeLatest(contractActions.getContract, getContract);
  yield takeLatest(contractActions.editContract, editContract);
  yield takeLatest(contractActions.getPayents, getPayents);
  yield takeLatest(contractActions.editSpecialContract, editSpecialContract);
  yield takeLatest(contractActions.getConfigIpc, getConfigIpc);
  yield takeLatest(contractActions.getPaymentsHide, getPaymentsHide);
  yield takeLatest(
    contractActions.renterDefineNewContract,
    renterDefineNewContract
  );
  yield takeLatest(contractActions.newContractSign, newContractSign);
  yield takeLatest(contractActions.skipayment, skipayment);
  yield takeLatest(contractActions.downContract, downContract);
  yield takeLatest(contractActions.editDayPay, editDayPay);
  yield takeLatest(contractActions.editContractV2, editContractV2);
}

export default function* () {
  yield all([ActionWatcher()]);
}

