import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { DollarOutlined, RedoOutlined, BankOutlined, UploadOutlined, StarOutlined, ContainerOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { ShowButton } from '../components/ShowButton/ShowButton'
import { formmatterCurrency } from 'common/utilities';
import moment from 'moment';
import { billingActions } from 'services/Billing/BillingSlice';



export const columns = (isModalVisible, setIsModalVisible, bill, setBill, selectedRows, setSelectedRows) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, limit } = pagination

    const getBillDetail = (data) => {
        dispatch(billingActions.getListDescription({ page, limit, filter: { bill_id: data } }))
    }

    const regenBill = (data) => {
        let answer = window.confirm("Desea Regenerar la informacion de facturacion de este elemento?");
        if (answer) {
            let filter_send = { ...filter };
            if (filter.company_id === undefined) {
                filter_send.company_id = 0;
            }
            dispatch(billingActions.regenBillInmo({ page, limit, filter: filter_send, bill_id: data }))
        }
    }


    const sendBillAccountant = (data) => {
        let answer = window.confirm("Desea enviar esta informacion para generar factura en sistema contable?");
        if (answer) {
            let filter_send = { ...filter };
            if (filter.company_id === undefined) {
                filter_send.company_id = 0;
            }
            dispatch(billingActions.sendBillAccountantSystem({ page, limit, filter: filter_send, bill_id: data }))
        }
    }

    // Función para manejar la selección de filas
    const handleRowSelect = (row) => {
        const index = selectedRows.findIndex(selectedRow => selectedRow.id === row.id);

        // Si la fila ya está seleccionada, la quitamos del estado
        if (index !== -1) {
            const newSelectedRows = [...selectedRows];
            newSelectedRows.splice(index, 1);
            setSelectedRows(newSelectedRows);
        } else { // Si la fila no está seleccionada, la agregamos al estado
            setSelectedRows([...selectedRows, row]);
        }
    };

    return [
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (data) => {
                let code = data.codeBilling;
                if (code == null) {
                    return <div>
                        <input
                            type="checkbox"
                            checked={selectedRows.some(selectedRow => selectedRow.id === data.id)}
                            onChange={() => handleRowSelect(data)}
                        />
                    </div>
                }
            }
        },
        {
            title: 'Inmobiliaria',
            dataIndex: 'company',
            key: 'company',
            render: (data) => {
                return <>{(data.name)}</>
            }
        },
        {
            title: 'Fecha creacion',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data) => {
                return moment(data).format("LL")
            }

        },
        {
            title: 'Cantidad contratos facturados',
            dataIndex: 'itemsBillings',
            key: 'itemsBillings'
        },
        {
            title: 'Total facturado',
            dataIndex: 'valueBilling',
            key: 'valueBilling',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        },
        {
            title: 'Codifgo de factura',
            dataIndex: 'codeBilling',
            key: 'codeBilling'
        },
        {
            title: 'Fecha de facturacion',
            dataIndex: 'expeditionDate',
            key: 'expeditionDate',
            render: (data) => {
                let label = "";
                if (data) {
                    label = moment(data).format("LL");
                }
                return label
            }
        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            render: (data) => {
                return (<>
                    {/* Botón para enviar factura si codeBilling es null */}
                    {/* Botones principales */}
                    <div>
                        <ShowButton
                            icon={<ContainerOutlined />}
                            onClick={() => {
                                getBillDetail(data.id);
                                history.push(`/billing/${data.id}`);
                            }}
                        />
                        <ShowButton
                            onClick={() => {
                                setIsModalVisible(true);
                                setBill(data);
                            }}
                        />
                    </div>

                    {/* Botón para regenerar la factura */}
                    <ShowButton
                        icon={<RedoOutlined />}
                        onClick={() => regenBill(data.id)}

                    />
                    {data.codeBilling == null && (
                        <ShowButton
                            icon={<CloudUploadOutlined />}
                            onClick={() => sendBillAccountant(data.id)}
                        />
                    )}
                </>)
            }
        }

    ]
}

export const columnsDetail = (isModalVisible, setIsModalVisible, billItem, setBillItem) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { pagination, filter } = useSelector(state => state.sysblueprint)
    const { limit } = pagination

    return [
        {
            title: 'Propiedad',
            dataIndex: '',
            key: '',
            render: (data) => {
                return <>{(data.metadata.property.title)}</>
            }
        },
        {
            title: 'Total facturado',
            dataIndex: 'valueBilling',
            key: 'valueBilling',
            render: (data) => {
                let label = formmatterCurrency(data);
                if (data == 0) {
                    label += " - dentro de tarifa fija"
                }
                return label;
            }
        },
        //{
        //    title: 'Acciones',
        //    key: '',
        //    dataIndex: '',
        //    render: (data) => {
        //        let label = "";
        //        label = <> 
        //            <ShowButton icon={<BankOutlined />}   onClick={() => { setIsModalVisible(true); setBillItem(data) }} /> 
        //        </>
        //        return label; 
        //    }
        //}

    ]
}

export const tablePaginationBill = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(billingActions.getList({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}

export const tablePaginationBillDetail = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(billingActions.getListDescription({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}


export const columnsBill = () => {

    return [
        {
            title: 'Item',
            dataIndex: '',
            key: '',
            render: (text, record, index) => {
                return <>{index + 1}</>;
            }
        },
        {
            title: 'Descripcion',
            dataIndex: '0',
            key: '',
        }
        ,
        {
            title: 'Cantidad',
            dataIndex: '2',
            key: 'id',
        }
        ,
        {
            title: 'Vr. Unitario',
            dataIndex: '3',
            key: '',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        }
        ,
        {
            title: 'Vr. Bruto',
            dataIndex: '3',
            key: '',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        }
        ,
        {
            title: 'Total',
            dataIndex: '3',
            key: '',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        }
    ];
}

export const columnsBillDetail = () => {
    return [
        {
            title: 'Propiedad',
            dataIndex: '',
            key: '',
            render: (data) => {
                return <>{(data.metadata.bill_plan_name.name + " : " + data.metadata.property.title)}</>
            }
        },
        {
            title: 'Total facturado',
            dataIndex: 'valueBilling',
            key: 'valueBilling',
            render: (data) => {
                let label = formmatterCurrency(data);
                if (data == 0) {
                    label += " - dentro de tarifa fija"
                }
                return label;
            }
        }
    ]
}