import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spin, Radio, Row, Col, PageHeader, Modal, Button, Select } from 'antd'
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { shoppingActions } from '../../../services/Shopping/ShoppingSlice'
import { columns } from './constans'
import { Filter } from './sections/Filter'
import { ModalDispersionData } from './sections/ModalDispersionData'
import { ShowButton } from '../components/ShowButton/ShowButton';
import { ModalDownloadData } from './sections/ModalDownloadData';
import { ModalUploadDoc } from './sections/ModalUploadDoc';
import moment from 'moment';
import { ModalDownloadBankFormat } from './sections/ModalDownloadBankFormat';
import { useLocation } from 'react-router-dom';

export const Dispersion = () => {

  const location = useLocation();
  const shouldShowMenu = location.pathname === '/dispersion';
  const on_property = location.pathname.startsWith('/property/');

  const today = new Date("01-01-2024");
  const formattedDate = today.toISOString().split('T')[0];
  const dispatch = useDispatch()
  const { dispersions, status, pagination, filterData, reload } = useSelector(state => state.dispersion)
  const { page, limit, total } = pagination
  const [disDownload, setDisDownload] = useState(null);
  const [regenerateData, setRegenerateData] = useState(null);
  const { user, property } = useSelector((state) => state.property);
  const [startDate, setStartDate] = useState(formattedDate);
  const [userEmail, setUserEmail] = useState('');
  const [inmo, setInmo] = useState('Todos');
  //const [property, setProperty] = useState('');
  const [selectionType, setSelectionType] = useState('11');
  const [current, setCurrent] = useState(0);
  const [error, setError] = useState('');
  const [isModalVisible, setIsModalVisible] =
    useState(false);
  const [isModalDownloadVisible, setIsModalDownloadVisible] =
    useState(false);
  const [cart, setCart] =
    useState(false);
  const [isModalUploadVisible, setIsModalUploadVisible] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const currentColumn = columns(isModalVisible, setIsModalVisible, cart, setCart, disDownload, setDisDownload, regenerateData, setRegenerateData, selectedRows, setSelectedRows, isModalUploadVisible, setIsModalUploadVisible);
  const [updateView, setUpdateView] =
    useState(false);
  const [isModalDownloadBankFormatVisible, setIsModalDownloadBankFormatVisible] =
    useState(false);
  const [viewFilter, setViewFilter] = useState(true);
  const [localFilter, setLocalFilter] = useState([]);

  useEffect(async () => {
    setLocalFilter(filterData);
    if (property) {
      setUpdateView(false)
      let filterData_local = {
        period: startDate,
      };
      if (userEmail !== "" && userEmail !== undefined && userEmail !== null) {
        filterData_local.user_email = userEmail;
      }
      if (filterData?.property_id != property.id) {
        filterData_local.property_id = property.id;
      } else if (property != undefined && on_property) {

        filterData_local.property_id = property.id;
      }
      await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData_local }))
      await dispatch(dispersionActions.onChangeReload({ value: false }))
      setError('');
    }
  }, [property])

  useEffect(async () => {
    if (reload == true) {
      await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData }))
      setUpdateView(false)
      /*if (startDate) {
        let filterData_local = {
          period: startDate,
        };
        if (filterData !== null) {
          if (inmo != "Todos") {
            if (filterData['context'] != inmo) {
              filterData_local.context = inmo;
            }
          } else if (filterData['context'] != "Todos") {
            filterData_local.context = filterData['context'];
          }
        }
        if (userEmail !== "" && userEmail !== undefined && userEmail !== null && localFilter.user_email == userEmail) {
          filterData_local.user_email = userEmail;
        }
        if (property != undefined && on_property) { 
          filterData_local.property_id = property.id;
        } else if (filterData.property_id != undefined && on_property ) { 
          filterData_local.property_id = filterData.property_id;
        } 
        await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData_local }))
        await dispatch(dispersionActions.onChangeReload({ value: false }))
        setError('');
      }*/
    }

  }, [reload])

  useEffect(() => {
    if (disDownload != null) {
      // se habre el modal para seleccionar la fecha

      let answer = window.confirm("confirme que este pago ya fue aprobado por el banco");
      if (answer) {
        let updateData = {
          "status_id": 8,
          "dispersion_register_id": parseInt(disDownload.id)
        }
        dispatch(dispersionActions.updateDisRegister({ updateData }))
      }
      setDisDownload(null);
    }

  }, [disDownload])

  useEffect(async () => {
    if (regenerateData != null) {
      let answer = window.confirm("Desea Regenerar la informacion de dispersion de este pago? , " +
        "tenga en cuenta que esta operacion podria modificar registros que ya esten pagados asociados al recaudo");
      if (answer) {

        let updateData = {
          "shopping_cart": regenerateData.shoppingCartId ? parseInt(regenerateData.shoppingCartId) : 0,
          "contract_id": parseInt(regenerateData.contractId),
          "code_gen": regenerateData.consecutiveDisGen ? parseInt(regenerateData.consecutiveDisGen) : 0
        }
        await dispatch(dispersionActions.regenDataDisRegister({ updateData }))
        //if (startDate) {
        //  let filterData = {
        //    period: startDate,
        //  };
        //  if (inmo != "Todos") {
        //    filterData.context = inmo;
        //  }
        //  if (userEmail !== "" && userEmail !== undefined && userEmail !== null) {
        //    filterData.user_email = userEmail;
        //  }
        //  //console.log('Filter Data:', filterData); 
        //  //await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData }))
        //  setError('');
        //}
      }
      setDisDownload(null);
    }
  }, [regenerateData])

  const handleOk = async () => {
    await dispatch(dispersionActions.onChangeReload({ value: true }))
    //dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
    setIsModalDownloadVisible(false);
    setCart(false);
    await dispatch(dispersionActions.onChangeReload({ value: true }))
    //dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
  };

  const handleUploadOk = () => {
  };

  const handleUploadCancel = () => {
    setIsModalUploadVisible(false);
    setCart(false);
  };
  const handleDownloadBankCancel = () => {
    setIsModalDownloadBankFormatVisible(false);
  };


  const handlePagination = async (page, limit) => {
    await dispatch(dispersionActions.onChangeReload({ value: true }))
    //dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
  }

  // Función para seleccionar todas las filas
  const handleSelectAll = () => {
    if (dispersions.length >= 1) {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        let valid_reg = [];
        dispersions.forEach(element => {
          let errors = element.metadata[0].error;
          if (element.statusId != 8 && errors.length === 0) {
            valid_reg.push(element)
          }
        });
        setSelectedRows(valid_reg); // Asigna todas las filas como seleccionadas
      }
      setSelectAll(!selectAll); // Cambia el estado de selección de todos los checkboxes
    }
  };


  const listLastApproved = async () => {
    let startDate_piv = moment(startDate);
    const date_init_moment = moment(startDate);
    let date_end = date_init_moment
      .add(-6, 'month')
      .toISOString()
      .split('T')[0];
    let filterData = {
      period_end: startDate_piv.toISOString().split('T')[0],
      period: date_end,
    };
    await dispatch(dispersionActions.listLastApproved({ filterData: filterData }))
  }

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total
  }


  return (
    <div className="user">
      {/*      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888' }}
        extra={[
        ]}
      /> */}


      <Spin spinning={status === "loading"}>

        {shouldShowMenu &&
          <>
            <Filter />
            {
              dispersions.length ? (
                <Col style={{ textAlign: "right", padding: "5px" }}>
                  <label>Dispersion bancaria : </label>
                  <Button
                    style={{
                      borderRadius: '5px',
                      borderColor: '#d82067',
                      padding: '5px 31px',
                      height: 'auto',
                      fontSize: 'medium',
                      marginTop: "10px"
                    }}
                    type="primary"
                    onClick={() => setIsModalDownloadBankFormatVisible(true)}
                  >
                    Generar
                  </Button>
                </Col>) : ""
            }
          </>
        }

        <Table
          title={() =>
            <>
              <Row justify="space-between" style={{ marginBottom: '20px' }}>
                <Col flex={1}>
                  <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Dispersiones</span>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: '0px' }}>
                <Col>
                  <Button
                    style={{
                      borderRadius: '5px',
                      borderColor: '#d82067',
                      padding: '5px 20px',
                      height: 'auto',
                      fontSize: 'medium',
                    }}
                    className="btn-default"
                    onClick={handleSelectAll}
                  >
                    {selectAll ? 'Desseleccionar todo' : 'Seleccionar todo'}
                  </Button>
                </Col>

                <Col>
                  <Button
                    style={{
                      borderRadius: '5px',
                      borderColor: '#d82067',
                      padding: '5px 20px',
                      height: 'auto',
                      fontSize: 'medium',
                    }}
                    className="btn-default"
                    onClick={() => setIsModalDownloadVisible(true)}
                  >
                    Aprobar
                  </Button>
                </Col>

                <Col>
                  {!property &&
                    <Button
                      style={{
                        borderRadius: '5px',
                        borderColor: '#d82067',
                        padding: '5px 20px',
                        height: 'auto',
                        fontSize: 'medium',
                      }}
                      className="btn-default"
                      onClick={listLastApproved}
                    >
                      Últimos aprobados
                    </Button>}
                </Col>
              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={dispersions}
          scroll={{ x: 400 }}
          pagination={tablePagination}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={"Informacion de dispersion"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalDispersionData
            cart={cart}
          />
        </Modal>
      }
      {isModalDownloadVisible &&
        <Modal
          title={"Confirmacion de pagos"}
          visible={isModalDownloadVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalDownloadData
            carts={selectedRows}
          />
        </Modal>
      }
      {isModalUploadVisible &&
        <Modal
          title={"Archivos de soporte"}
          visible={isModalUploadVisible}
          onOk={handleUploadOk}
          onCancel={handleUploadCancel}
          width={1100}
          footer={null}
        >
          <ModalUploadDoc
            cart={cart}
          />
        </Modal>
      }
      {isModalDownloadBankFormatVisible &&
        <Modal
          title={"Generar archivo bancario"}
          visible={isModalDownloadBankFormatVisible}
          onOk={handleUploadOk}
          onCancel={handleDownloadBankCancel}
          width={630}
          footer={null}
        >
          <ModalDownloadBankFormat
          selectedRows={selectedRows}
          />
        </Modal>
      }
    </div>
  )
}